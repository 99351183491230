<template>
    <content-view class="knowledge-base__material-content">
        <div class="knowledge-base__logo">
            W
        </div>
        <div
            v-if="isPageLoaded"
            class="knowledge-base__material"
        >
            <company class="show-print-css" :domain="currentDomain" />
            <div class="material__content editor__content" id="page-print">
                <new-editor
                    class="content__editor"
                    :data="description"
                    :editable="false"
                    top-placeholder="Название материала"
                    bottom-placeholder="Описание материала"
                />
            </div>
        </div>
        <loading-spinner v-else />
    </content-view>
</template>

<script>
    import ContentView from '@/views/menu/ContentView'
    import session from '@/api/session'
    import LoadingSpinner from '@/components/LoadingSpinner'
    import { mapGetters } from 'vuex'
    import { metaTitle } from '@/mixins/meta_title'
    import NewEditor from '@/components/editor/newEditor'

    export default {
        name: 'Material',
        mixins: [metaTitle],
        components: {
            NewEditor,
            Company: () =>
                import('@/components/sidebar/header/companies/Company'),
            ContentView,
            LoadingSpinner
        },
        data() {
            return {
                materialId: this.$route.params.material_id,
                materialSharing: this.$route.query.sharing,
                material: {},
                description: '',
                isPageLoaded: false
            }
        },
        computed: {
            ...mapGetters('sidebar', ['currentDomain']),
            setTitle() {
                return this.material ? this.material.title : ''
            }
        },
        async mounted() {
            this.$store.dispatch('sidebar/setSidebarOpened', false)
            const { materialId, materialSharing } = this
            try {
                const request = session.get(
                    `/api/v1/material/${materialId}/?sharing=${materialSharing}`
                )
                const response = await request
                this.description = response.data.text
                this.content = response.data.text
                this.material = response.data

                this.isPageLoaded = true
            } catch (error) {
                this.$router.push('/404')
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '#sass/v-style';
    @import 'knowledge_base';

    .knowledge-base {
        &__logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            background: #fbc04f;
            color: #000;
            font-weight: bold;
        }
    }

    /deep/ .knowledge-base {
        &__material {
            @include below(1280px) {
                padding: 0 20px;
            }
            .material {
                &__content {
                    margin-top: 30px;
                }
            }
        }
    }
</style>
